import { Outlet } from "react-router-dom";
import Footer from "../../components/footer/Footer";
import styles from "./RootLayout.module.css";
const RootLayout = () => {
  return (
    <div className={styles.container}>
        <div style={{margin:0}}><Outlet /></div>
        <Footer />
    </div>
  );
};
export default RootLayout;
